import React, { useState } from 'react';
import { Row, Col, Anchor, Modal, Form, notification, Input } from 'antd';
import { Link } from 'react-router-dom';

import BannerHistoria from './BannerHistoria';
import BannerPublicaciones from './BannerPublicaciones';
import CarouselProductosSocios from './CarouselProductosSocios';
import Blog from './Blog';
import '../../../assets/css/home.css';


import banner_promo from '../../../assets/images/sitio/banner/banner_pronostico.png';
import banner_promo_movil from '../../../assets/images/sitio/banner/banner_pronostico_movil.png';
import logo_banner from '../../../assets/images/sitio/banner/alim2021.png';

import banner_jornada from '../../../assets/images/sitio/banner/banner_JORNADAcereales.jpg';
import banner_jornada_movil from '../../../assets/images/sitio/banner/banner_JORNADAcereales_movil.jpg';



import cereal_bl from '../../../assets/images/sitio/1_HOME/icono_cereal_.png';
import cereal_am from '../../../assets/images/sitio/1_HOME/icono_cereal_am.png';
import trigo_bl from '../../../assets/images/sitio/1_HOME/icono_trigo.png';
import trigo_am from '../../../assets/images/sitio/1_HOME/icono_trigo_am.png';
import harina_bl from '../../../assets/images/sitio/1_HOME/icono_harina.png';
import harina_am from '../../../assets/images/sitio/1_HOME/icono_harina_am.png';
import img_completo from '../../../assets/images/sitio/banner/banner_compendio_completo_.jpg';

import img_cap1 from '../../../assets/images/sitio/publicaciones/compendio/banner_web.jpg';

import img_comercio from '../../../assets/images/sitio/1_HOME/icono_comercioexterior.png';
import img_normativa from '../../../assets/images/sitio/1_HOME/icono_normativa.png';

import img_pan_bl from '../../../assets/images/sitio/1_HOME/icono_panificacion.png';
import img_pan_am from '../../../assets/images/sitio/1_HOME/icono_panificacion_am.png';
import img_pasta_bl from '../../../assets/images/sitio/1_HOME/icono_semola.png';
import img_pasta_am from '../../../assets/images/sitio/1_HOME/icono_semola_am.png';
import img_germen_bl from '../../../assets/images/sitio/1_HOME/icono_salvado.png';
import img_germen_am from '../../../assets/images/sitio/1_HOME/icono_salvado_am.png';
import img_gluten_bl from '../../../assets/images/sitio/1_HOME/icono_gluten.png';
import img_gluten_am from '../../../assets/images/sitio/1_HOME/icono_gluten_am.png';
import img_esquela from '../../../assets/images/sitio/1_HOME/Canimolt_esquela_web.jpg';

import documento_descarga from '../../../assets/images/sitio/DOCUMENTOS/ASAMBLEA_ALIM_2021_PRESENTACIONES.zip';

import BannerCapitulos from './BannersCapitulos';
import Contador from './Contador';

const Home = () => {
  const [mostrar_cereal_am, mostrarCerealAm] = useState(false);
  const [mostrar_trigo_am, mostrarTrigoAm] = useState(false);
  const [mostrar_harina_am, mostrarHarinaAm] = useState(false);

  const [mostrar_pan_am, mostrarPanAm] = useState(false);
  const [mostrar_pasta_am, mostrarPastaAm] = useState(false);
  const [mostrar_germen_am, mostrarGermenAm] = useState(false);
  const [mostrar_gluten_am, mostrarGlutenAm] = useState(false);


  return (
    <>

      <Row>
        <Col span={24} className="banner_principal">
          <Row justify="center" align="middle">
            <Col md={20} lg={18}>
              <p className="banner_primera_linea color_blanco">Importancia del Trigo</p>
              <p className="banner_segunda_linea color_negro">Y SUS BENEFICIOS<span className="color_blanco"> EN LA SALUD</span></p>
            </Col>
            <Col xs={20} md={20} lg={16}>
              <Row justify="center" align="middle">
                <Col xs={6} lg={2}>
                  <hr className="linea_blc_home" />
                </Col>
                <Col xs={24} md={20} lg={14}>
                  <p className="banner_text color_blanco">Es el principal cereal para el consumo humano, en el mundo,<br />
                    siendo también el más cultivado y consumido, donde sus<br />
                    productos son muy importantes en la nutrición humana.</p>
                </Col>
                <Col xs={14} lg={{ span: 3, offset: 1 }}>
                  <p className="contenedor_boton">
                    <Link to="/trigo" class="btn-blanco boton-blanco">VER MÁS</Link>
                  </p>
                </Col>
                <Col xs={0} lg={{ span: 0, offset: 0 }}>
                  <p className="contenedor_boton">
                    <Link class="btn-blanco boton-blanco">SIGUIENTE</Link>
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={0} className="">
          <Contador dateTarget="05/15/2024 11:00:00 AM" />
        </Col>
        <Col xs={24} md={24}>
          <Link to="/compendio-estadistico-2022-2023"><img src={img_cap1} style={{ width: '100%' }} alt="Compendio1" /></Link>
        </Col>
        <Col xs={0}>
          <img src={img_esquela} style={{ width: '100%' }} />
        </Col>
        <Col xs={0}>
          <BannerCapitulos />
        </Col>
        <Col xs={0} md={0}>
          <a href="https://www.gob.mx/cms/uploads/attachment/file/693827/SIAP-PronosticoAgrometeorologico.pdf" target='_blank'><img src={banner_promo} alt="" style={{ width: '100%' }} /></a>
        </Col>
        <Col xs={0} md={0}>
          <a href="https://www.gob.mx/cms/uploads/attachment/file/693827/SIAP-PronosticoAgrometeorologico.pdf" target='_blank'><img src={banner_promo_movil} alt="" style={{ width: '100%' }} /></a>
        </Col>
        <Col xs={0} md={0}>
          <Link to="/compendio-estadistico-completo"><img src={img_completo} style={{ width: '100%' }} alt="Compendio" /></Link>
        </Col>
        <Col xs={0} md={0}>
          <a href="https://www.youtube.com/live/Em6x8bHFXuY?feature=share" target='_blank'><img src={banner_jornada_movil} alt="" style={{ width: '100%' }} /></a>
        </Col>
        <Col span={24} className="back_negro" style={{ padding: '3% 0% 2% 0%',display:'none' }}>
          <Row align="middle">
            <Col xs={{ span: 14, offset: 6 }} md={{ span: 6, offset: 3 }}>
              <p className="texto_pub_banner">
                <img src={logo_banner} style={{ width: '100%' }} alt="alim 2021" />
              </p>
            </Col>
            <Col xs={{ span: 16, offset: 4 }} md={{ span: 6, offset: 4 }} >
              <p className="contenedor_boton">
                <strong><a href={documento_descarga} class="btn-banner boton-banner" download>DESCARGAR PRESENTACIONES</a></strong>
              </p>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row align="middle">
            <Col xs={{ span: 20, offset: 2 }} lg={{ span: 6, offset: 2 }} className="contenedor_productos_desc">
              <a href="/cereales" target="_blank" className="img_producto" onMouseEnter={() => mostrarCerealAm(true)} onMouseLeave={() => mostrarCerealAm(false)}>
                <img alt="" src={mostrar_cereal_am ? cereal_am : cereal_bl} className="img_producto" /></a>
              <p className={mostrar_cereal_am === true ? 'titulo_desc_producto_amarillo' : 'titulo_desc_producto'}>Cereales</p>
              <p className="desc_texto_productos">Son los alimentos más importantes en la dieta humana y animal, debido a sus altas cualidades nutrimentales, ya que contienen hidratos de carbono, proteínas, sales, minerales y vitaminas, nutrientes indispensables para el ser humano.</p>
            </Col>
            <Col xs={{ span: 20, offset: 2 }} lg={{ span: 6, offset: 1 }}>
              <a href="/trigo" target="_blank" className="img_producto" onMouseEnter={() => mostrarTrigoAm(true)} onMouseLeave={() => mostrarTrigoAm(false)}>
                <img alt="" src={mostrar_trigo_am ? trigo_am : trigo_bl} className="img_producto" /></a>
              <p className={mostrar_trigo_am === true ? 'titulo_desc_producto_amarillo' : 'titulo_desc_producto'}>Trigo</p>
              <p className="desc_texto_productos">Es una planta gramínea de la familia del césped, su crecimiento promedio es de un metro de altura. Sus hojas brotan muy pronto y van seguidas por tallos delgados rematados por espigas cuyos granos molidos se saca la harina.</p>
            </Col>
            <Col xs={{ span: 20, offset: 2 }} lg={{ span: 6, offset: 1 }}>
              <a href="/harina" target="_blank" className="img_producto" onMouseEnter={() => mostrarHarinaAm(true)} onMouseLeave={() => mostrarHarinaAm(false)}>
                <img alt="" src={mostrar_harina_am ? harina_am : harina_bl} className="img_producto" /></a>
              <p className={mostrar_harina_am === true ? 'titulo_desc_producto_amarillo' : 'titulo_desc_producto'}>Harina</p>
              <p className="desc_texto_productos">Es el polvo que se obtiene de la molienda del grano de trigo maduro, entero o quebrado, limpio, sano y seco, se elimina gran parte de la cascarilla (salvado) y el germen. El resto se tritura hasta obtener un grano de finura adecuada.</p>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <BannerHistoria />
        </Col>
        <Col xs={0} lg={24} className="contenedor_comer_marco">
          <Row align="middle">
            <Col lg={{ span: 9, offset: 1 }} className="comercio_home">
              <p className="comercio_home_text">COMERCIO EXTERIOR</p>
              <Link to="/comercio-exterior-y-marco-normativo#comercio"><img alt="" src={img_comercio} className="home_img_comercio" /></Link>
            </Col>
            <Col lg={{ span: 1, offset: 1 }}>
              <div class="vertical_line"></div>
            </Col>
            <Col lg={{ span: 9, offset: 2 }} className="comercio_home">
              <p className="comercio_home_text">MARCO NORMATIVO</p>
              <Link to="/comercio-exterior-y-marco-normativo#marco"><img alt="" src={img_normativa} className="home_img_comercio" /></Link>
            </Col>
          </Row>
        </Col>
        <Col lg={{ span: 22, offset: 1 }} className="contenedor_cadenas">
          <Row >
            <Col xs={{ span: 0, offset: 0 }} lg={{ span: 16, offset: 4 }}>
              <p className="titulo_cadenas_home color_negro">CADENAS PRODUCTIVAS</p>
            </Col>
            <Col xs={{ span: 0, offset: 0 }} lg={{ span: 6, offset: 0 }} className="contenedor_info_cadenas">
              <img alt="" className="img_desc_cadenas" src={mostrar_pan_am ? img_pan_am : img_pan_bl} />
            </Col>
            <Col xs={{ span: 0, offset: 0 }} lg={{ span: 6, offset: 0 }} className="contenedor_info_cadenas">
              <img alt="" className="img_desc_cadenas" src={mostrar_pasta_am ? img_pasta_am : img_pasta_bl} />
            </Col>
            <Col xs={{ span: 0, offset: 0 }} lg={{ span: 6, offset: 0 }} className="contenedor_info_cadenas">
              <img alt="" className="img_desc_cadenas" src={mostrar_germen_am ? img_germen_am : img_germen_bl} />
            </Col>
            <Col xs={{ span: 0, offset: 0 }} lg={{ span: 6, offset: 0 }} className="contenedor_info_cadenas">
              <img alt="" className="img_desc_cadenas" src={mostrar_gluten_am ? img_gluten_am : img_gluten_bl} />
            </Col>

            <Col xs={{ span: 0, offset: 0 }} lg={{ span: 6, offset: 0 }} className="contenedor_info_cadenas">
              <p className="text_decs_cadenas">Los obtenidos por procesos continuos de fabricación, estandarizados, con alto grado de automatización y en lotes de mayor escala. Pueden utilizar aditivos para alimentos y comercializarse tanto a granel como preenvasados.</p>
            </Col>
            <Col xs={{ span: 0, offset: 0 }} lg={{ span: 6, offset: 0 }} className="contenedor_info_cadenas">
              <p className="text_decs_cadenas">La sémola es una harina de gránulo grueso libre de tegumentos y germen, y es obtenida de la molienda del trigo maduro.<br />Las pastas mexicanas cada día se colocan mejor en los mercados externos, en el año 2007 se vendieron más de 47,450 toneladas.</p>
            </Col>
            <Col xs={{ span: 0, offset: 0 }} lg={{ span: 6, offset: 0 }} className="contenedor_info_cadenas">
              <p className="text_decs_cadenas">Salvado, producto rico en vitaminas, minerales y fibra, por ello se recomienda en la dieta alimenticia.<br />El germen de trigo es rico en proteínas, vitaminas B1, B2, B6, vitamina E y ácido fólico. (importante para evitar malformaciones).</p>
            </Col>
            <Col xs={{ span: 0, offset: 0 }} lg={{ span: 6, offset: 0 }} className="contenedor_info_cadenas">
              <p className="text_decs_cadenas">Gluten, producto rico en vitaminas, minerales y fibra, por ello se recomienda en la dieta alimenticia. Proteínas, vitaminas B1, B2, B6, vitamina E y ácido fólico. (importante para evitar malformaciones).</p>
            </Col>

            <Col xs={{ span: 0, offset: 0 }} lg={{ span: 6, offset: 0 }} className="contenedor_info_cadenas">
              <p className="contenedor_boton" onMouseEnter={() => mostrarPanAm(true)} onMouseLeave={() => mostrarPanAm(false)}>
                <Link to="/cadenas-productivas#panificacion" class="btn-amarillo boton-amarillo" >VER MÁS</Link>
              </p>
            </Col>
            <Col xs={{ span: 0, offset: 0 }} lg={{ span: 6, offset: 0 }} className="contenedor_info_cadenas">
              <p className="contenedor_boton" onMouseEnter={() => mostrarPastaAm(true)} onMouseLeave={() => mostrarPastaAm(false)}>
                <Link to="/cadenas-productivas#semola" class="btn-amarillo boton-amarillo">VER MÁS</Link>
              </p>
            </Col>
            <Col xs={{ span: 0, offset: 0 }} lg={{ span: 6, offset: 0 }} className="contenedor_info_cadenas">
              <p className="contenedor_boton" onMouseEnter={() => mostrarGermenAm(true)} onMouseLeave={() => mostrarGermenAm(false)} >
                <Link to="/cadenas-productivas#salvado-germen" class="btn-amarillo boton-amarillo">VER MÁS</Link>
              </p>
            </Col>
            <Col xs={{ span: 0, offset: 0 }} lg={{ span: 6, offset: 0 }} className="contenedor_info_cadenas">
              <p className="contenedor_boton" onMouseEnter={() => mostrarGlutenAm(true)} onMouseLeave={() => mostrarGlutenAm(false)}>
                <Link to="/cadenas-productivas" class="btn-amarillo boton-amarillo">VER MÁS</Link>
              </p>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 22, offset: 1 }} lg={{ span: 0 }} className="contenedor_cadenas">
          <Row >
            <Col xs={{ span: 20, offset: 2 }} md={{ span: 16, offset: 4 }}>
              <p className="titulo_cadenas_home color_negro">CADENAS PRODUCTIVAS</p>
            </Col>
            <Col xs={{ span: 20, offset: 2 }} lg={{ span: 6, offset: 0 }} className="contenedor_info_cadenas">
              <img alt="" className="img_desc_cadenas" src={mostrar_pan_am ? img_pan_am : img_pan_bl} onMouseEnter={() => mostrarPanAm(true)} onMouseLeave={() => mostrarPanAm(false)} />
              <p className="text_decs_cadenas">Los obtenidos por procesos continuos de fabricación, estandarizados, con alto grado de automatización y en lotes de mayor escala. Pueden utilizar aditivos para alimentos y comercializarse tanto a granel como preenvasados.</p>
              <p className="contenedor_boton">
                <Link to="/cadenas-producivas#panificacion" class="btn-amarillo boton-amarillo">VER MÁS</Link>
              </p>
            </Col>
            <Col xs={{ span: 20, offset: 2 }} lg={{ span: 6, offset: 0 }} className="contenedor_info_cadenas">
              <img alt="" className="img_desc_cadenas" src={mostrar_pasta_am ? img_pasta_am : img_pasta_bl} onMouseEnter={() => mostrarPastaAm(true)} onMouseLeave={() => mostrarPastaAm(false)} />
              <p className="text_decs_cadenas">La sémola es una harina de gránulo grueso libre de tegumentos y germen, y es obtenida de la molienda del trigo maduro.<br />Las pastas mexicanas cada día se colocan mejor en los mercados externos, en el año 2007 se vendieron más de 47,450 toneladas.</p>
              <p className="contenedor_boton">
                <Link to="/cadenas-producivas#semola" class="btn-amarillo boton-amarillo">VER MÁS</Link>
              </p>
            </Col>
            <Col xs={{ span: 20, offset: 2 }} lg={{ span: 6, offset: 0 }} className="contenedor_info_cadenas">
              <img alt="" className="img_desc_cadenas" src={mostrar_germen_am ? img_germen_am : img_germen_bl} onMouseEnter={() => mostrarGermenAm(true)} onMouseLeave={() => mostrarGermenAm(false)} />
              <p className="text_decs_cadenas">Salvado, producto rico en vitaminas, minerales y fibra, por ello se recomienda en la dieta alimenticia.<br />El germen de trigo es rico en proteínas, vitaminas B1, B2, B6, vitamina E y ácido fólico. (importante para evitar malformaciones).</p>
              <p className="contenedor_boton">
                <Link to="/cadenas-producivas#salvado-germen" class="btn-amarillo boton-amarillo">VER MÁS</Link>
              </p>
            </Col>
            <Col xs={{ span: 20, offset: 2 }} lg={{ span: 6, offset: 0 }} className="contenedor_info_cadenas">
              <img alt="" className="img_desc_cadenas" src={mostrar_gluten_am ? img_gluten_am : img_gluten_bl} onMouseEnter={() => mostrarGlutenAm(true)} onMouseLeave={() => mostrarGlutenAm(false)} />
              <p className="text_decs_cadenas">Gluten, producto rico en vitaminas, minerales y fibra, por ello se recomienda en la dieta alimenticia. Proteínas, vitaminas B1, B2, B6, vitamina E y ácido fólico. (importante para evitar malformaciones).</p>
              <p className="contenedor_boton">
                <Link to="/cadenas-producivas#gluten" class="btn-amarillo boton-amarillo">VER MÁS</Link>
              </p>
            </Col>
          </Row>
        </Col>
        <Col span={24} className="contenedor_encasa">
          <Row>
            <Col xs={{ span: 16, offset: 8 }} md={{ span: 10, offset: 14 }} className="barra_encasa">
              <strong>#YoMeQuedoEnCasa</strong>
            </Col>
            <Col span={24}>
              <br />
            </Col>
            <Col span={16} offset={4}>
              <p className="text_encasa">“En la industria molinera de trigo <strong>continuamos trabajando</strong> para asegurar
                el <strong>abasto y la sana alimentación</strong> de los mexicanos"
              </p>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <BannerPublicaciones />
        </Col>
        <Col span={24}>
          <Blog />
        </Col>
        <Col span={24}>
          <CarouselProductosSocios />
        </Col>
      </Row>
      
    </>
  );
}

export default Home;